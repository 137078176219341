.heightlogo {
    height: 50px;
}

.mt {
    margin-top: 68px;
}

.mb-35 {
    margin-bottom: 35px;
}

.NoDot {
    list-style: none;
    margin-left: -18px;
}

.WidCard {
    width: 340px;
}

.mt4 {
    margin-top: 4rem;
}

.bg-color {
    background-color: #E7DFDD;
}

.text-color {
    color: #0e0B16;
}

.btn-color {
    background-color: #A239CA;
    color: white;
}

.nav-btn, .navbar-toggler {
    background-color: #E7DFDD;
    color: #0e0B16;
    margin-left: 10px;
    font-weight: bold;
}

.nav-bar {
    background-color: #0e0B16;
}

body {
    background-color: #E7DFDD;
    color: #0e0B16;

}

h3 {
    color: #A239CA;
    font-weight: bold;
    font-family: 'Amiri', serif;
}

.Homeinfo {
    color: #A239CA;
}

.pb {
    padding-bottom: 8rem;
}

.pt {
    padding-top: 6.5rem;
}

.Hair {
    padding-top: 1rem;
}

h4{
    font-family: 'Amiri', serif;
}

h5{
    font-family: 'Amiri', serif;
}

h6{
    font-family: 'Amiri', serif;
}

/*.ff{
    font-family: 'Amiri', serif;
    font-weight: bold;
}*/
h1{
    font-family: 'Amiri', serif;
}

h2 {
    color: #A239CA;
    font-weight: bold;
    border-bottom: 2px solid #4717f6;
    padding-bottom: 5px;
    margin-bottom: 20px;
    font-family: 'Great Vibes', cursive;
    font-size: 55px;
}

.google {
    width: 600px;
    height: 450px;
}

.bg{
    background-image: url(./background.png);
    background-repeat: no-repeat;
    /* background-image: url(./background.png); */
}

@media only screen and (max-width: 600px) {
    .google {
        width: 350px;
        height: 250px;
    }
    Footer {
        font-size: 12px;
    }
    .hometext{
        font-size: 15px;
    }
    .ImageGallery{
        display: none;
    }
    .nav-btn{
        border-bottom: 1px solid #0e0B16;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.MenTop{
    margin-top: 108rem;
}